<template>
  <div class="">
    <center-title
      :title="
        acountMode == 'add'
          ? '子账号新增'
          : acountMode == 'edit'
          ? '子账号修改'
          : '子账号详情'
      "
      :border="false"
    >
    </center-title>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="162px"
      class="ruleForm"
    >
      <el-form-item label="用户头像" prop="avatar">
        <el-upload
          style="width: 90px; height: 90px"
          :action="`${download}/file/formimage`"
          class="info-avatar"
          :disabled="acountMode == 'View'"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="successUpload"
          :show-file-list="false"
        >
          <el-image
            class="avatar row-center"
            :src="userAvatar || ruleForm.avatar"
            fit="cover"
          >
            <div slot="error" class="image-slot" v-if="acountMode == 'add'">
              <i class="el-icon-camera"></i>
            </div>
          </el-image>
        </el-upload>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile" class="mb30">
        <el-input
          v-model="ruleForm.mobile"
          placeholder="请输入手机号码"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="账号密码"
        prop="password"
        class="mb30"
        v-if="acountMode != 'View'"
      >
        <el-input
          v-model="ruleForm.password"
          :placeholder="acountMode == 'Edit' ? '默认不修改' : '请输入账号密码'"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号昵称" prop="nickname" class="mb30">
        <el-input
          v-model="ruleForm.nickname"
          placeholder="请输入账号昵称"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" prop="true_name" class="mb30">
        <el-input
          v-model="ruleForm.true_name"
          placeholder="请输入真实姓名"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户类型" prop="group_id" class="mb30">
        <el-select
          v-model="ruleForm.group_id"
          placeholder="请选择账号身份"
          :disabled="acountMode == 'View'"
        >
          <el-option label="主账号" :value="1" v-if="ruleForm.group_id == 1">
          </el-option>
          <el-option
            v-for="(item, index) in filteredTypeList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
   
      <!-- <el-form-item label="部门" prop="cost" class="mb30">
        <el-input v-model="ruleForm.mobile1" placeholder="部门" :disabled="acountMode == 'View'"></el-input>
      </el-form-item> -->
 <DIV v-if="this.$store.getters.userInfo.company_id == 57">
     <el-form-item label="部门" prop="department_id" class="mb30">
        <el-select
           v-model="ruleForm.department_id"
        placeholder="请选择账号部门"
       :disabled="acountMode == 'View'"
     >
           <el-option
              label="主账号"
             :value="1"
             v-if="ruleForm.department_id == 1"
        >
           </el-option>
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="限购品类" class="mb30">
          <el-input
            v-model="ruleForm.allow_id"
            placeholder="请输入限购品类"
            :disabled="acountMode == 'View'"
          ></el-input>
        </el-form-item>

       <el-form-item label="限购额度" class="mb30">
          <el-input
           v-model="ruleForm.quota_id"
           placeholder="请输入限购额度"
         :disabled="acountMode == 'View'"
        ></el-input>
       </el-form-item>
    </DIV>

      <el-form-item
        label="管控公司"
        prop="checkList"
        class="mb30"
        v-if="ruleForm.group_id != 2"
      >
        <el-checkbox-group
          v-model="ruleForm.checkList"
          :disabled="acountMode == 'View'"
        >
          <el-checkbox
            :label="item.id"
            v-for="(item, index) in companyGroupList"
            :key="index"
            >{{ item.company_name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="成本单元" prop="cost_unit" class="mb30" v-if="userInfo.company_id == 61">
        <el-input
          v-model="ruleForm.cost_unit"
          placeholder="请输入成本单元"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="acountMode != 'View'">
        <el-button
          type="primary"
          class="white md bg-primary"
          @click="submitForm('ruleForm')"
        >
          {{ acountMode == "add" ? "新增" : "保存修改" }}
        </el-button>
        <el-button
          class="reset bg-center white md"
          @click="resetForm('ruleForm')"
          v-if="acountMode != 'Edit'"
          >重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import { vxRule } from "@/utils/validator";
import Cache from "@/utils/cache";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {
        mobile: "", //手机号码
        password: "", //密码
        nickname: "", //昵称
        true_name: "", //真实姓名
        group_id: "", //账号身份
        avatar: "",
        company_auth_id: "",
        checkList: [],
        cost_unit: ""
      },
      rules: {
        mobile: vxRule(true, "Mobile", "blur", "请输入手机号码"),
        password: vxRule(true, "Password", "blur", "请输入密码"),
        nickname: vxRule(true, null, "blur", "请输入昵称"),
        true_name: vxRule(true, null, "blur", "请输入真实姓名"),
        group_id: vxRule(true, null, "change", "请选择账号身份"),
        cost: vxRule(true, null, "cost", "请输入成本"),
        quota_category: vxRule(true, null, "quota_category", "请输入限购品类"),
        quota_amount: vxRule(true, null, "quota_amount", "请输入限购金额"),
        checkList: vxRule(true, null, "change", "请选择管控公司"),
      },
      value: [],
      download: process.env.VUE_APP_DOWNLOAD_URL,
      typeList: [
        {
          label: "科室科员",
          value: 2,
        },
        {
          label: "科室主任",
          value: 3,
        },
        {
          label: "采购",
          value: 4,
        },
        // 南宁招标需求只针对德安医疗显示 company_id = 57
        {
          label: "财务",
          value: 5,
        }
      ],
      groupList: [
        {
          label: "内科",
          value: 2,
        },
        {
          label: "外科",
          value: 3,
        },
        {
          label: "妇科",
          value: 4,
        },
        {
          label: "儿科",
          value: 5,
        },
        {
          label: "心内科",
          value: 6,
        },
        {
          label: "呼吸科",
          value: 7,
        },
        {
          label: "血液科",
          value: 8,
        },
        {
          label: "消化科",
          value: 9,
        },
        {
          label: "内分泌科",
          value: 10,
        },
        {
          label: "胃肠外科",
          value: 11,
        },
        {
          label: "肝胆科",
          value: 12,
        },
        {
          label: "腹壁疝科",
          value: 13,
        },
        {
          label: "大肠科",
          value: 14,
        },  {
          label: "骨科",
          value: 15,
        },
        {
          label: "关节科",
          value: 16,
        },
        {
          label: "脊柱外科",
          value: 17,
        },
      ],
      acountMode: "add",
      userAvatar: "",
      companyGroupList: [],
      userInfo: {}, //用户信息
    };
  },
  computed: {
    filteredTypeList() {
      // 只在company_id为57时显示总经办
      return this.typeList.filter(item => {
        if (item.value === 5 && this.userInfo.company_id !== 57) {
          return false;
        }
        return true;
      });
    }
  },
  watch: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let api =
            this.acountMode == "add"
              ? this.$api.addSubAccountApi
              : this.$api.EditSubAccountDetailApi;

          api({
            ...this.ruleForm,
            user_id: this.ruleForm.id,
            avatar: this.userAvatar || this.ruleForm.avatar,
            company_auth_id:
              this.ruleForm.group_id == 2
                ? ""
                : this.ruleForm.checkList.join(","),
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubAccountDetail() {
      this.$api
        .getSubAccountDetailApi({
          user_id: this.$route.query.id,
        })
        .then((res) => {
          this.ruleForm = res.data;
          // console.log(res.data, 'res.data')
          this.ruleForm.checkList = res.data.company_auth_list.map((el) => {
            return el.id;
          });
          // console.log(this.ruleForm);
        });
    },
    successUpload(res) {
      let { code, data } = res;
      this.userAvatar = res.data.uri;
    },
    getCompanyGroup() {
      this.$api.getCompanyGroupApi().then((res) => {
        if (res.code == 1) {
          this.companyGroupList = res.data;
        }
      });
    },
  },
  created() {
    this.userInfo = Cache.get("userInfo")
    
    if (this.$route.name == "acountEdit") {
      this.acountMode = "Edit";
      this.rules.password = vxRule(false, "Password", "blur", "请输入密码");
    }
    if (this.$route.name == "acountView") {
      this.acountMode = "View";
    }
    if (this.acountMode == "Edit" || this.acountMode == "View") {
      this.getSubAccountDetail();
    }
    this.getCompanyGroup();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .image-slot {
  font-size: 28px;
  color: #ccc;
}

.el-checkbox {
  height: 48px;
  line-height: 48px;
}

.info-avatar {
  width: 90px;
  height: 90px;
  margin-right: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f7fa;
  border: 1px solid #f5f7fa;

  .avatar {
    width: 90px;
    height: 90px;
  }

  .tips {
    bottom: 0;
    height: 25px;
    left: 0;
    right: 0;
    line-height: 20px;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}

/deep/ .el-form {
  .el-select {
    width: 100%;
  }

  .el-button,
  .el-form-item__label,
  .el-input__inner {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }

  .el-button,
  .reset {
    width: 150px;
    margin: 10px auto 0;
    padding: 0;
  }

  .reset {
    margin-left: 30px;
    border: none;
    background-color: $-color-border;
  }
}

.el-select-dropdown__item {
  font-size: 16px;
  height: 44px;
  line-height: 44px;
}
</style>
